(function () {
	'use strict';

	angular
		.module('app')
		.factory('officeCalendar', [
			'officeCalendarConfig',
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'daybackIO',
			'utilities',
			'manageFetch',
			officeCalendar,
		]);

	function officeCalendar(
		officeCalendarConfig,
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		daybackIO,
		utilities,
		manageFetch
	) {
		//Create our connection for google services
		var connection = createConnection();

		var eventEditQueue = {};

		var primarySource;
		var primarySourceTemplate;

		officeBk.setErrorReporter(errorReporter);

		const unscheduledTerm = 'DayBack Unscheduled';

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			auth: auth,
			deauthorize: deauthorize,
			repeatingConfig: repeatingConfig,
			getRepeatingRule: getRepeatingRule,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getUnscheduled: getUnscheduled,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			getOneEvent: getOneEvent,
			onSignOut: onSignOut,
			deleteSourceEvent: deleteSourceEvent,
			deleteRepeatingInstance: deleteRepeatingInstance,
			updateRepeatingEventUntil: updateRepeatingEventUntil,
			updateSourceEvent: updateSourceEvent,
		};

		function getConfig() {
			return officeCalendarConfig.config();
		}

		function getFieldMap() {
			return officeCalendarConfig.fieldMap();
		}

		function getUnusedMap() {
			return officeCalendarConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return officeCalendarConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return officeCalendarConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return officeCalendarConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function createConnection() {
			var connection = officeCalendarConfig.apiConnection();
			return connection;
		}

		function errorReporter(
			status,
			additionalMessage,
			preventDeauth,
			callback
		) {
			var config = seedcodeCalendar.get('config');

			var message =
				'There was a problem communicating with Microsoft 365.';

			if (config.passthroughEditErrors) {
				return;
			}

			if (additionalMessage) {
				message += ' ' + additionalMessage;
			}

			if (!preventDeauth && (status === 401 || status === 403)) {
				message += ' - ' + 'please re-authorize DayBack.';
				if (primarySourceTemplate)
					deauthorize(callback, false, primarySourceTemplate);
			}
			if (!config.suppressEditEventMessages) {
				utilities.showMessage(message, 0, 8000, 'error');
			}
		}

		function auth(
			callback,
			source,
			statusOnly,
			forceConsent,
			preventUserList,
			includeGroupSchedules
		) {
			var user = daybackIO.getUser();
			if (!source) {
				source = primarySource;
			}
			officeBk.auth({
				userID: user.id,
				sourceID: source.id,
				statusOnly: statusOnly,
				forceConsent: forceConsent,
				redirectAuth: connection.authIsRedirect,
				redirectAuthFunction: connection.authRedirectFunction,
				preventUserList: preventUserList,
				includeGroupCalendars: !source.excludeGroupCalendars,
				callback: callback,
			});
		}

		function deauthorize(callback, saveToken, sourceTemplate) {
			var user = daybackIO.getUser();
			var source = primarySource;
			var schedules = seedcodeCalendar.get('schedules');
			var calendarElement = seedcodeCalendar.get('element');
			var eventSources = seedcodeCalendar.get('eventSources');

			var retainedSchedules = [];
			var removedSchedules = [];
			officeBk.deauthorize(user.id, source.id, saveToken, function () {
				// Remove auth status
				sourceTemplate.status.authed = false;

				// Only try to update calendar if it exists - Not used when initiate from settings
				if (seedcodeCalendar.get('view')) {
					// Remove Schedules and events
					for (var i = 0; i < schedules.length; i++) {
						if (schedules[i].sourceID !== source.id) {
							retainedSchedules.push(schedules[i]);
						} else {
							removedSchedules.push(schedules[i]);
						}
					}

					seedcodeCalendar.init('schedules', retainedSchedules);

					for (var i = 0; i < removedSchedules.length; i++) {
						// Remove event sources from fullcalendar
						calendarElement.fullCalendar(
							'removeEventSource',
							removedSchedules[i].source,
							removedSchedules[i].id
						);

						//Remove event source from eventSourcesObject
						if (
							eventSources.indexOf(removedSchedules[i].source) >
							-1
						) {
							eventSources.splice(
								eventSources.indexOf(
									removedSchedules[i].source
								),
								1
							);
						}
					}

					// Remove orphanes
					var events = calendarElement.fullCalendar('clientEvents');
					for (var i = 0; i < events.length; i++) {
						if (events[i].schedule.sourceID === source.id) {
							calendarElement.fullCalendar(
								'removeEvents',
								events[i]
							);
						}
					}
				}

				if (callback) {
					callback();
				}
			});
		}

		function repeatingConfig() {
			return {
				repeatEnabled: true,
				schedulesEnabled: 'all',
				footers: {never: true},
				intervalShows: {all: true},
				createOnly: false,
				yearlyMultiSelect: true,
				dateChangeClear: false,
			};
		}

		function getRepeatingRule(request, ruleObject) {
			var config = seedcodeCalendar.get('config');
			var weekDays = [
				'sunday',
				'monday',
				'tuesday',
				'wednesday',
				'thursday',
				'friday',
				'saturday',
			];
			var pattern = {};
			var range = {};

			var frequency = ruleObject.frequency.toLowerCase();

			if (frequency === 'monthly') {
				frequency = 'absoluteMonthly';
			} else if (frequency === 'yearly') {
				frequency = 'absoluteYearly';
				request.start.dateTime.day(0);
			}

			if (
				frequency === 'absoluteMonthly' ||
				frequency === 'absoluteYearly'
			) {
				pattern.dayOfMonth = ruleObject.dayNumbers[0];
			}

			pattern.firstDayOfWeek = weekDays[config.firstDayOfWeek || 0];
			// pattern.index = "first";
			pattern.interval = ruleObject.interval;
			pattern.month = moment(request.start.dateTime).month() + 1;
			pattern.type = frequency;

			if (ruleObject.days) {
				pattern.daysOfWeek = ruleObject.days;
			}

			range.startDate = moment(request.start.dateTime).format(
				'YYYY-MM-DD'
			);
			range.type = 'noEnd';

			// endDate: "2021-05-17",
			// numberOfOccurrences: 0,
			// recurrenceTimeZone: "Pacific Standard Time",
			return {
				recurrence: {
					pattern: pattern,
					range: range,
				},
			};
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var userProfile = officeBk.getUserProfile();
			var source;

			primarySourceTemplate = sourceTemplate;

			//Locate our dayback source data
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					source = sources[i];
					if (userProfile) {
						source.name = userProfile.displayName;
						source.email = userProfile.mail;
					}
					//Run our authorization
					primarySource = source;

					auth(
						authCallBack,
						source,
						true,
						null,
						source.attendeesAsContacts,
						!source.excludeGroupCalendars
					);
					break;
				}
			}

			function authCallBack(auth) {
				if (auth) {
					officeBk.calendarList(calendarListCallBack);
				} else {
					callback(false, sourceTemplate);
				}
				sourceTemplate.status.authed = auth;
			}

			function calendarListCallBack(calendarList) {
				applySchedules(calendarList, source.id);
			}

			function applySchedules(schedules, sourceID) {
				var scheduleID;
				//Execute our test
				//indexDBTest(sourceTemplate.id, schedules);

				for (var i = 0; i < schedules.length; i++) {
					//Merge source data that is missing from the returned calendar / schedule
					for (var property in source) {
						if (schedules[i][property] === undefined) {
							schedules[i][property] = source[property];
						}
					}

					schedules[i].sourceID = sourceID;

					//Add dayback source data to schedules because this is not a 1:1 source type
					schedules[i].customFields = source.customFields
						? JSON.parse(JSON.stringify(source.customFields))
						: null;
					schedules[i].customActions = source.customActions
						? JSON.parse(JSON.stringify(source.customActions))
						: null;
					schedules[i].eventActions = source.eventActions
						? JSON.parse(JSON.stringify(source.eventActions))
						: null;
					schedules[i].fieldMap = source.fieldMap
						? JSON.parse(JSON.stringify(source.fieldMap))
						: null;
					schedules[i].unusedMap = source.unusedMap
						? JSON.parse(JSON.stringify(source.unusedMap))
						: null;
					schedules[i].allowHTMLMap = source.allowHTMLMap
						? JSON.parse(JSON.stringify(source.allowHTMLMap))
						: null;
					schedules[i].labelMap = source.labelMap
						? JSON.parse(JSON.stringify(source.labelMap))
						: null;

					scheduleID = utilities.stringToID(schedules[i].id);

					if (source[scheduleID]) {
						//Append schedule specific items to already specified source items
						for (var property in source[scheduleID]) {
							if (
								schedules[i][property] &&
								(property === 'eventActions' ||
									property === 'customActions' ||
									property === 'customFields' ||
									property === 'fieldMap' ||
									property === 'unusedMap' ||
									property === 'allowHTMLMap' ||
									property === 'labelMap')
							) {
								for (var calendarProperty in source[scheduleID][
									property
								]) {
									if (
										property === 'fieldMap' ||
										property === 'unusedMap' ||
										property === 'allowHTMLMap' ||
										property === 'labelMap'
									) {
										if (
											!schedules[i][property][
												calendarProperty
											]
										) {
											schedules[i][property][
												calendarProperty
											] =
												source[scheduleID][property][
													calendarProperty
												];
										}
									} else {
										schedules[i][property][
											calendarProperty
										] =
											source[scheduleID][property][
												calendarProperty
											];
									}
								}
							} else {
								schedules[i][property] =
									sourceTemplate.settings[property] &&
									!sourceTemplate.settings[property]
										.scheduleOnly &&
									source[property] !== 'disable-global'
										? source[property]
										: source[scheduleID][property];
							}
						}
					}

					//Set source specific custom field properties
					if (schedules[i].customFields) {
						for (var property in schedules[i].customFields) {
							schedules[i].customFields[property].mutateInput =
								customFieldMutateInput;
						}
					}

					calendarIO.cleanSchedule(schedules[i], sourceTemplate);

					//Normalize read only
					schedules[i].editable =
						sourceTemplate.editable && schedules[i].canEdit;

					//Normalize the calendar name
					// schedules[i].name = schedules[i].summaryOverride && !schedules[i].editable ? schedules[i].summaryOverride : schedules[i].summary;

					//deal with our colors here for now
					var colors = officeCalendarConfig.colors();

					var keys = Object.keys(colors);

					if (schedules[i].color === 'auto') {
						schedules[i].backgroundColor =
							colors[keys[i % (keys.length - 1)]]; // modulus to go back to first after length is reached
					} else {
						schedules[i].backgroundColor =
							colors[schedules[i].color];
					}
				}

				callback(schedules, sourceTemplate);
			}
		}

		function changeScheduleName(name, callback, schedule) {
			var request;
			// if (schedule.editable) {
			request = {name: name};
			officeBk.updateCalendar(schedule.id, request, function (result) {
				callback(result);
			});
			// }
			// else {
			// 	request = {summaryOverride: name};
			// 	officeBk.updateCalendarList(schedule.id, request, function(result) {
			// 		callback(result);
			// 	});
			// }

			return name;
		}

		function changeScheduleColor(color, callback, schedule) {}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		function dayNameToRruleArray(day, rruleObj) {
			var arrayResult;
			if (day && Array.isArray(day)) {
				arrayResult = [];
				for (var i = 0; i < day.length; i++) {
					arrayResult.push(dayNameToRruleArray(day[i], rruleObj));
				}

				return arrayResult;
			}

			if (day) {
				day = day.toUpperCase();
			} else {
				return;
			}

			day = day.substring(0, 2);

			return rruleObj[day];
		}

		function recurrenceToRrule(recurrence) {
			var ruleFromString;
			if (!recurrence) {
				return;
			}
			// recurrence = {};
			// recurrence.pattern = {
			// 	dayOfMonth: 0,
			// 	firstDayOfWeek: "sunday",
			// 	index: "first",
			// 	interval: 1,
			// 	month: 0,
			// 	type: "daily",
			// };

			// recurrence.range = {
			// 	endDate: "2021-05-17",
			// 	numberOfOccurrences: 0,
			// 	recurrenceTimeZone: "Pacific Standard Time",
			// 	startDate: "2021-02-17",
			// 	type: "endDate",
			// };

			var recurrenceRule = '';
			var pattern = recurrence.pattern;
			var range = recurrence.range;

			var RRule = rrule.RRule;

			var rule = new RRule({
				freq: RRule[recurrence.pattern.type.toUpperCase()],
				interval: recurrence.pattern.interval || 1,
				wkst: recurrence.pattern.firstDayOfWeek
					? dayNameToRruleArray(
							recurrence.pattern.firstDayOfWeek,
							RRule
						)
					: null,
				byweekday: recurrence.pattern.daysOfWeek
					? dayNameToRruleArray(recurrence.pattern.daysOfWeek, RRule)
					: [],
				dtstart: moment(recurrence.range.startDate)
					.hours(0)
					.minutes(0)
					.seconds(0)
					.utc()
					.toDate(),
				until: recurrence.range.endDate
					? moment(recurrence.range.endDate)
							.hours(0)
							.minutes(0)
							.seconds(0)
							.utc()
							.toDate()
					: moment()
							.add(1, 'years')
							.hours(0)
							.minutes(0)
							.seconds(0)
							.utc()
							.toDate(),
			});

			// recurrenceRule += 'DTSTART=' + (moment(recurrence.range.startDate).hours(0).minutes(0).seconds(0).utc().format('YYYYMMDDTHHmmss[Z]')) + '\n';
			// recurrenceRule += 'RRULE:';
			// recurrenceRule += 'FREQ=' + (recurrence.pattern.type.toUpperCase()) + ';';
			// recurrenceRule += 'INTERVAL=' + (recurrence.pattern.interval || 1) + ';';

			// recurrenceRule += 'WKST=' + (utilities.dayNameToNumber(recurrence.pattern.firstDayOfWeek)) + ';';

			// recurrenceRule += 'COUNT=' + (5 || 1) + ';';

			// if (recurrence.pattern.daysOfWeek) {
			// 	// recurrenceRule += 'BYDAY=' + (utilities.dayNameToNumber(recurrence.pattern.daysOfWeek)) + ';';
			// }
			// if (recurrence.range.endDate) {
			// 	recurrenceRule += 'UNTIL=' + (moment(recurrence.range.endDate).hours(0).minutes(0).seconds(0).utc().format('YYYYMMDDTHHmmss[Z]'));
			// }

			// console.log('RULESTRING', recurrenceRule)

			// ruleFromString = rrule.rrulestr(recurrenceRule);

			ruleFromString = rule;

			return ruleFromString;
		}

		function eventDatesFromRrule(rruleObj, rangeStart, rangeEnd) {
			return rruleObj.between(
				rangeStart.toDate(),
				rangeEnd.toDate(),
				'inc=true'
			);
		}

		function createEventRepetitions(recurrence, rangeStart, rangeEnd) {
			if (!recurrence) {
				return;
			}
			var rruleObj = recurrenceToRrule(recurrence);
			return eventDatesFromRrule(rruleObj, rangeStart, rangeEnd);
		}

		function getUnscheduled(callbackFunc, schedule) {
			const config = seedcodeCalendar.get('config');
			const fieldMap = schedule.fieldMap;
			const calendarId = schedule.id;
			const options = {};

			var callback = manageFetch.create(
				schedule.id,
				'unscheduled',
				callbackFunc
			).confirm;

			//make sure we have field info for this object ig includeFilters is specified

			if (!schedule.allowUnscheduled) {
				callback();
				return;
			}

			options['$filter'] = `categories/any(x:x eq '${unscheduledTerm}')`;

			officeBk.eventList(
				calendarId,
				fieldMap,
				null,
				processEvents,
				options,
				true,
				schedule.isGroup
			);

			function processEvents(result) {
				if (!result || result.error) {
					schedule.error = result.error;
					callback();
					return;
				}

				callback(mutateEvents(result.value, schedule));
			}
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');
			var view = seedcodeCalendar.get('view');
			var fieldMap = schedule.fieldMap;
			var queryDate = calendarElement.fullCalendar('getDate');

			var calendarId = schedule.id;
			var queryStart = moment(start).format();
			var queryEnd = moment(end).format();
			var queryDayRange = end.diff(start, 'days');
			var requestCount;
			var requestCallbackCount = 0;
			var requestResult = [];

			options = {};

			var customizedRepeats =
				(config.automaticRepetitions && queryDayRange <= 45) ||
				view.name !== 'basicHorizon'
					? false
					: true;

			options.endDateTime = queryEnd;
			options.startDateTime = queryStart;

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			// Not using the following code to loop through the request range and split into multiple requests
			// if (queryDayRange > 1825) {
			// 	requestCount = Math.ceil(queryDayRange / 1825);
			// 	console.log('request count', requestCount);

			// 	for (var i = 0; i < requestCount; i++) {
			// 		console.log('i', i);
			// 		options.startDateTime = start.clone().add(1825 * i, 'days').format();
			// 		options.endDateTime = start.clone().add(1825 * (i + 1), 'days').format();
			// 		console.log('start', options.startDateTime);
			// 		console.log('end', options.endDateTime);
			// 		officeBk.eventList(calendarId, fieldMap, function(result) {
			// 			requestCallbackCount++;
			// 			console.log('callback', requestCallbackCount);
			// 			console.log('result', result);

			// 			for (var ii = 0; ii < result.value.length; ii++) {
			// 				requestResult.push(result.value[ii]);
			// 			}
			// 			if (requestCallbackCount === requestCount) {
			// 				console.log('requestResult', requestResult);
			// 				processEvents({value: requestResult});
			// 			}
			// 		}, options);
			// 	}
			// }

			if (queryDayRange > 1825) {
				// set the end of range to maximum of 1825 days after start of range
				options.endDateTime = moment(start).add(1825, 'days').format();

				var message =
					'Microsoft 365 only supports a maximum range of 5 years. Only Microsoft 365 events for the first 5 years of this view will be shown. Shorten the duration of the view to 5 years or less or navigate into the future to see more events.';
				utilities.showModal(
					'Microsoft 365 Out of Range',
					message,
					null,
					null,
					'OK',
					null
				);
			}

			// Run before events fetched action
			const actionCallbacks = {
				confirm: function () {
					officeBk.eventList(
						calendarId,
						fieldMap,
						fetchID,
						processEvents,
						options,
						false,
						schedule.isGroup
					);
				},
				cancel: function () {
					callback(mutateEvents([], schedule));
				},
			};

			const actionResult = calendarIO.runEventActions(
				'beforeEventsFetched',
				{schedule: schedule},
				true,
				actionCallbacks,
				null
			);

			if (!actionResult) {
				return;
			}

			officeBk.eventList(
				calendarId,
				fieldMap,
				fetchID,
				processEvents,
				options,
				false,
				schedule.isGroup
			);

			function processEvents(result) {
				if (!result || result.error) {
					schedule.error = result.error;
					callback();
					return;
				}

				callback(mutateEvents(result.value, schedule));
			}
		}

		function getOneEvent(eventId, callback, schedule) {
			var calendarElement = seedcodeCalendar.get('element');
			var config = seedcodeCalendar.get('config');
			var queryDate = calendarElement.fullCalendar('getDate');

			var calendarId = schedule.id;

			officeBk.getEvent(eventId, processEvents);

			function processEvents(result) {
				if (!result || result.error) {
					schedule.error = result.error;
					callback();
					return;
				}
				callback(mutateEvents([result], schedule));
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = JSON.parse(item);
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return item ? moment(item).format(schedule.fileDateFormat) : '';
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(schedule.fileTimestampFormat)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return item;
				// return JSON.stringify(item);
			} else {
				return item;
			}
		}

		function mutateEvents(events, schedule) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}

			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var repeatingDates;
			var output = [];
			var repeatingEvent;
			var repeatingDate;
			var dayDuration;
			var eventStart;
			var eventEnd;

			var view = seedcodeCalendar.get('view');
			var start = view.startOverride || view.start;
			var end = view.end;
			var locationString;

			var recurrenceIDFormat;

			for (var i = 0; i < events.length; i++) {
				// 	console.log('rule result', createEventRepetitions(events[i].recurrence, start, end));
				// 	if (events[i].recurrence) {
				// 		recurrenceIDFormat = events[i].allDay ? 'YYYYMMDD' : 'YYYYMMDDTHHmmss[Z]';
				// 		repeatingDates = createEventRepetitions(events[i].recurrence, start, end);
				// 		eventStart = moment(events[i].start.dateTime);
				// 		eventEnd = moment(events[i].end.dateTime);
				// 		dayDuration = moment(eventEnd).diff(moment(eventEnd), 'days');
				// 		for (var ii = 0; ii < repeatingDates.length; ii++) {
				// 			repeatingEvent = JSON.parse(JSON.stringify(events[i]));

				// 			repeatingEvent.recurringEventID = events[i].id;

				// 			repeatingDate = moment(repeatingDates[ii]);
				// 			repeatingEvent.start.dateTime = repeatingDate.hour(eventStart.hour()).minute(eventStart.minute());
				// 			repeatingEvent.end.dateTime = repeatingDate.add(dayDuration, 'days').hour(eventEnd.hour()).minute(eventEnd.minute());

				// 			repeatingEvent.eventID = events[i].id + '_' + repeatingEvent.start.dateTime.utc().format(recurrenceIDFormat);

				// 			console.log('repeatingDate', repeatingDate);
				// 			output.push(mutate(repeatingEvent));
				// 		}
				// 	}
				// 	else {
				output.push(mutate(events[i]));
				// }
			}
			return output;

			function mutate(event) {
				//We can use this to create a recurring event in FullCalendar
				// if (event.recurringEventID) {
				//   event.id = event.recurringEventID;
				// }
				event.eventSource = schedule.id;
				event.schedule = schedule;
				event.color = schedule.backgroundColor;
				event.textColor = schedule.foregroundColor;

				//field specific transformations
				event.created = event.createdDateTime;
				event.eventID = event.id;

				//Add repeating id if it is a repetition
				if (event.seriesMasterId) {
					event.recurringEventID = event.seriesMasterId;
				}

				//office 365 has categories which come in as an array.
				event.status = !event.categories?.length
					? []
					: event.categories.filter((item) => {
							return item !== unscheduledTerm;
						});

				if (event.categories?.includes(unscheduledTerm)) {
					event.unscheduled = true;
				}

				//id for weblinks is a little different
				var webLink = event.webLink;
				var webLinkExplode = webLink.split('?ItemID=');

				// event.eventURL =
				// 	'https://outlook.office365.com/calendar/item/' +
				// 	encodeURIComponent(event.eventID);
				// event.attachmentsURL =
				// 	'https://outlook.office365.com/calendar/item/' +
				// 	encodeURIComponent(event.eventID);

				event.eventURL =
					'https://outlook.office365.com/calendar/deeplink/read/' +
					encodeURIComponent(event.eventID);
				event.attachmentsURL =
					'https://outlook.office365.com/calendar/deeplink/read/' +
					encodeURIComponent(event.eventID);

				if (webLinkExplode.length > 1) {
					event.webLinkId = webLinkExplode[1].split('&')[0];
				}

				if (event.start && event.start.dateTime) {
					if (event.isAllDay) {
						event.start = moment(
							moment(event.start.dateTime).format('YYYY-MM-DD')
						);
					} else {
						event.start =
							event.start.timeZone !== 'UTC'
								? $.fullCalendar.createTimezoneTime(
										moment(event.start.dateTime),
										false,
										true
									)
								: $.fullCalendar.createTimezoneTime(
										moment.utc(event.start.dateTime),
										false,
										true
									);
					}
				}
				if (event.end && event.end.dateTime) {
					if (event.isAllDay) {
						event.end = moment(
							moment(event.end.dateTime)
								.subtract(1, 'd')
								.format('YYYY-MM-DD')
						);
					} else {
						event.end =
							event.end.timeZone !== 'UTC'
								? $.fullCalendar.createTimezoneTime(
										moment(event.end.dateTime),
										false,
										true
									)
								: $.fullCalendar.createTimezoneTime(
										moment.utc(event.end.dateTime),
										false,
										true
									);
					}
				}

				//resources will be the organizer plus attendees
				var allResources = [];
				var allContacts = [];
				var allContactIds = [];
				// if(event.organizer && event.organizer.emailAddress && event.organizer.emailAddress.name) {
				// 	allResources.push(event.organizer.emailAddress.name);
				// }
				for (var i in event.attendees) {
					if (
						event.attendees[i].type === 'resource' &&
						event.attendees[i].emailAddress &&
						event.attendees[i].emailAddress.name
					) {
						allResources.push(event.attendees[i].emailAddress.name);
					} else if (
						event.attendees[i].emailAddress &&
						event.attendees[i].emailAddress.name
					) {
						//only if they're in the org, aka an user.
						if (
							officeBk.isUser(
								event.attendees[i].emailAddress.address
							)
						) {
							allResources.push(
								event.attendees[i].emailAddress.name
							);
						} else {
							//log them as a contact
							allContacts.push(
								event.attendees[i].emailAddress.name
							);
							allContactIds.push(
								event.attendees[i].emailAddress.name
							);
						}
					}
				}

				event.resource = !allResources ? [] : allResources;

				// //convert contact to array
				event.contactName = !allContacts ? [] : allContacts;
				event.contactID = !allContactIds ? [] : allContactIds;

				event.projectName = [];
				event.projectID = [];

				if (event.subject) {
					event.titleEdit = event.subject;
					event.title = event.subject;
				}

				//location, if a room is specified, then we'll use its office location
				// var resourceLocation = officeBk.userLocationByName(event.location.displayName);
				// if(resourceLocation) {
				// 	event.location = event.location.displayName + ' ( ' + resourceLocation + ' ) ';
				// }
				if (event.location) {
					locationString = '';
					if (
						event.location.address &&
						(event.location.address.street ||
							event.location.address.city ||
							event.location.address.state ||
							event.location.address.countryOrRegion)
					) {
						locationString = event.location.address.street;
						locationString = event.location.address.city
							? locationString + ' ' + event.location.address.city
							: locationString;
						locationString = event.location.address.state
							? locationString +
								' ' +
								event.location.address.state
							: locationString;
						locationString = event.location.address.postalCode
							? locationString +
								' ' +
								event.location.address.postalCode
							: locationString;
						locationString = event.location.address.countryOrRegion
							? locationString +
								' ' +
								event.location.address.countryOrRegion
							: locationString;
					} else if (event.location.displayName) {
						locationString = event.location.displayName;
					}

					event.location = locationString;
				}

				//work with html body.
				if (event.body && event.body.content) {
					var scale = 1.14285;
					var html = event.body.content;
					var htmlBodyContent;
					var htmlSplit;
					var srcArray;
					var regex;
					var srcCount;
					var stuffedFieldData;

					//remove img elements
					if (event.body.contentType === 'html') {
						// htmlBodyContent = html.match(/<body>[\s\S]*?<div .*?>([\s\S]*)<\/div>[\s\S]*?<\/body>/i);
						htmlBodyContent = html.match(
							/<body>([\s\S]*?)<\/body>/i
						);

						if (htmlBodyContent && htmlBodyContent[1]) {
							html = htmlBodyContent[1].trim();
						}
						html = html.replace(
							/class="[x_?]*dbk-/g,
							'class="dbk-'
						);

						// Get stuffed field data
						// stuffedFieldData = html.match(/<ul class="dbk-fields">[\s\S]*?<\/ul>/i)
						stuffedFieldData = html.match(
							/<span class="dbk-fields-content".*?>([\s\S]*?)<\/span>/i
						);
						html = html.replace(
							/<p class="dbk-fields".*?>[\s\S]*?<\/p>/i,
							''
						);
						if (stuffedFieldData && stuffedFieldData.length) {
							html = html.replace(stuffedFieldData[0], '');

							stuffedFieldData = stuffedFieldData[1];

							if (stuffedFieldData) {
								// Decode html entities and replace stray return characters that could mess up parsing json
								stuffedFieldData = utilities
									.decodeHtmlEntities(stuffedFieldData)
									.replace(/\r\n|\r|\n/g, ' ');

								try {
									stuffedFieldData =
										JSON.parse(stuffedFieldData);
								} catch (error) {
									stuffedFieldData = '';
								}
								event.stuffedFields = stuffedFieldData;
							}
						}

						// 	html = html.replace(stuffedFieldData, '');

						// 	stuffedFieldData = stuffedFieldData.match(/<li.*?>.*?<\/li>/gi);

						// 	if (stuffedFieldData) {

						// 		for (var i = 0; i < stuffedFieldData.length; i++) {
						// 			var matchedLine = stuffedFieldData[i].match(/<li.*?>(.*?)<\/li>/i);

						// 			if (matchedLine && matchedLine[1]) {
						// 				matchedLine = matchedLine[1].split(/:[\s\S]*?/i);
						// 				stuffedFields[matchedLine[0].trim()] = matchedLine[1].trim();
						// 			}

						// 			console.log('matched line', matchedLine);
						// 		}
						// 	}
						// 	console.log(stuffedFields);

						// }
						// else {
						// 	stuffedFieldData = null;
						// }

						// console.log('html', html);
						// console.log('stuffed field data', stuffedFieldData)

						//strip out images
						srcArray = html.match(
							/<img data-imagetype="AttachmentByCid"/g
						);
						if (srcArray) {
							event.hasImages = true;
							htmlSplit = html.split(
								'<img data-imagetype="AttachmentByCid"'
							);
							srcCount = htmlSplit.length;
							for (i = 1; i < srcCount; i++) {
								regex = new RegExp(
									'<img data-imagetype="AttachmentByCid"' +
										htmlSplit[i].split('>')[0] +
										'>'
								);
								html = html.replace(
									regex,
									'<a href="' +
										event.eventURL +
										'" target="_blank"><i class="fa fa-picture-o fa-lg" aria-hidden="true"></i></a>'
								);
							}

							// htmlSplit = html.split('<img');
							// srcCount = htmlSplit.length;
							// for(i=1; i < srcCount; i++) {
							// 	regex = new RegExp (  '<img' + htmlSplit[i].split('>')[0] + '>'  );
							// 	//if it's base64, then leave it in.
							// 	if(htmlSplit[i].split('>')[0].indexOf(' src="data:image/')===-1) {
							// 		//html = html.replace( regex , ' <span style="color:rgb(150,150,150)">&lt;image&gt;</span> ' );
							// 		html = html.replace( regex , ' <a id="officeViewLink" src="javascript:void(0)" onclick="officeBk.viewInOffice(\''+ event.webLinkId +'\' , $rootScope )">image</a> ' );
							// 	}
							// }
						}

						//inject target="_blank" into <a> elements
						html = html.replace(
							/<a href=/g,
							'<a target="_blank" href='
						);

						if (!htmlBodyContent) {
							// Below is commented out because we are now removing the parent content div removing any outlook styling
							//strip out all style attributes from divs
							// srcArray = html.split('<div id="');
							// for (i = 1 ; i < srcArray.length ; i++ ) {
							// 	regex = new RegExp(srcArray[i].split('style="')[1].split('"')[0]);
							// 	html = html.replace(regex, '');
							// }
							// //scale the inline styling to better match dayback's font sizes
							// html = html.replace(/font-size:\d{1,2}pt/g,
							// 	function ( string ) {
							// 		var number = Math.round ( string.match(/\d{1,2}/)[0] / scale );
							// 		return 'font-size:' + number + 'pt';
							// 	} );
						}

						event.description = html;
					} else {
						//is plain text
						event.description = event.body.content;
					}
				} else {
					//emulate our text area placeholder text if the html is empty
					// event.description = '<span style="color:rgb(150,150,150)">Description</span>';
				}
				event.allDay = event.isAllDay;

				if (stuffedFieldData) {
					var field;
					for (var property in stuffedFieldData) {
						// Get field map for property
						for (var field in fieldMap) {
							if (property === fieldMap[field]) {
								event[field] = stuffedFieldData[property];
								break;
							}
						}
					}
				}
				//return mulated event
				return calendarIO.cleanEvent(event);
			}
		}

		//Edit Events
		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule,
			sourceEventId,
			forceNewEvent
		) {
			var config = seedcodeCalendar.get('config');
			var fieldMap = schedule.fieldMap;
			var customFields = schedule.customFields;
			var allDay = changes.hasOwnProperty('allDay')
				? changes.allDay
				: event.allDay;
			var translatedProperty;
			var editObj = {};
			var additionalEditProperties = {};
			var fieldForStuffing = 'description';
			var stuffedFields = event.stuffedFields || {};
			var resourceOutput = [];
			var statusOutput = [];
			var fromRepeatCreation;
			var fromRepeatDeletion;

			if (
				changes.recurrence &&
				event.eventID &&
				!sourceEventId &&
				!forceNewEvent
			) {
				// This is changed from non repeating to repeating. We need to delete and create new to avoid timezone issues with DST
				changes = JSON.parse(JSON.stringify(event));
				changes.start = event.start.clone();
				changes.end = event.end.clone();
				editEvent(
					event,
					revertObject,
					revertFunc,
					changes,
					editID,
					function (eventResult) {
						deleteEvent(event, null, schedule);
						if (callback) {
							callback(eventResult);
						}
					},
					schedule,
					sourceEventId,
					true
				);
				return;
			}

			// Standardize start and end timezones because office 365 throws an error if start and end don't match
			if (changes.start || changes.end) {
				if (!changes.start) {
					changes.start = event.start.clone();
				}
				if (!changes.end) {
					changes.end = event.end.clone();
				}
			}

			var localtimezone = config.localTimezone;

			for (var property in fieldMap) {
				//Don't send unused / unmapped items to edit object
				if (!fieldMap[property] || schedule.unusedMap?.[property]) {
					continue;
				}

				//If the field is mapped and it has a change property, or if it is a new event write all non empty fields
				if (
					property !== 'eventID' &&
					property !== 'title' &&
					changes.hasOwnProperty(property)
				) {
					translatedProperty = fieldMap[property];
					//format start and stop as needed
					if (property === 'start' || property === 'end') {
						changes[property] =
							$.fullCalendar.timezoneTimeToLocalTime(
								changes[property],
								allDay
							);

						if (event.allDay) {
							editObj[translatedProperty] = {
								dateTime: changes[property]
									.clone()
									.hours(0)
									.minutes(0)
									.seconds(0)
									.format('YYYY-MM-DD[T]HH:mm'),
								timeZone: localtimezone,
							};
						} else {
							editObj[translatedProperty] = {
								dateTime: changes[property]
									.clone()
									.format('YYYY-MM-DD[T]HH:mm'),
								timeZone: localtimezone,
							};
						}
					} else if (property === 'status') {
						editObj[translatedProperty] = changes[property].slice(); //Using slice here to clone the array
						//Remove "none" label from statuses so we don't write it back to the source
						for (
							var i = 0;
							i < editObj[translatedProperty].length;
							i++
						) {
							if (
								editObj[translatedProperty][i] ===
								config.noFilterLabel
							) {
								editObj[translatedProperty][i] = '';
							} else {
								statusOutput.push(
									editObj[translatedProperty][i]
								);
							}
						}

						if (statusOutput && statusOutput.length) {
							editObj[translatedProperty] = statusOutput;
						}
					} else if (property === 'resource') {
						var resourceUser;
						editObj[translatedProperty] = changes[property].slice(); //Using slice here to clone the array
						//Remove "none" label from resources so we don't write it back to the source
						for (
							var i = 0;
							i < editObj[translatedProperty].length;
							i++
						) {
							if (
								editObj[translatedProperty][i] ===
								config.noFilterLabel
							) {
								editObj[translatedProperty][i] = '';
							}
							if (!editObj[translatedProperty][i]) {
								continue;
							}

							resourceUser = officeBk.isUser(
								editObj[translatedProperty][i],
								editObj[translatedProperty][i]
							);
							// Check if resource is an internal user
							if (resourceUser) {
								resourceOutput.push({
									// "emailAddress": {name: "Tanner Ellen", address: "tannerellen@me.com"},
									emailAddress: {
										address: resourceUser.mail,
										name: resourceUser.displayName,
									},
									// "proposedNewTime": {"@odata.type": "microsoft.graph.timeSlot"},
									status: {
										response: 'none',
										time: '0001-01-01T00:00:00Z',
									},
									type: 'required',
								});
							} else {
								resourceOutput.push({
									emailAddress: {
										name: editObj[translatedProperty][i],
									},
									// "proposedNewTime": {"@odata.type": "microsoft.graph.timeSlot"},
									status: {
										response: 'none',
										time: '0001-01-01T00:00:00Z',
									},
									type: 'resource',
								});
							}
						}

						// Add optional attendees
						if (event.contactName && event.contactName.length) {
							for (var i = 0; i < event.contactName.length; i++) {
								for (
									var ii = 0;
									ii < event.attendees.length;
									ii++
								) {
									if (
										(event.attendees[ii].type ===
											'optional' ||
											schedule.attendeesAsContacts) &&
										event.attendees[ii].emailAddress
											.name === event.contactName[i]
									) {
										resourceOutput.push(
											event.attendees[ii]
										);
									}
								}
							}
						}

						if (resourceOutput && resourceOutput.length) {
							editObj[translatedProperty] = resourceOutput;
						} else {
							editObj[translatedProperty] = [];
						}
					} else if (property === 'location') {
						editObj[translatedProperty] = {
							// "address": {"@odata.type": "microsoft.graph.physicalAddress"},
							// "coordinates": {"@odata.type": "microsoft.graph.outlookGeoCoordinates"},
							displayName: changes[property],
							// "locationEmailAddress": "string",
							// "locationUri": "string",
							locationType: 'default',
							// "uniqueId": "string",
							// "uniqueIdType": "string"
						};
					} else if (property === 'recurrence') {
						if (
							changes[property] &&
							Array.isArray(changes[property]) &&
							!changes[property].length
						) {
							// We are changing so it's not a repeating event
							fromRepeatDeletion = true;
							editObj[property] = null;
						} else {
							fromRepeatCreation = true;
							editObj[translatedProperty] = JSON.parse(
								JSON.stringify(changes[property])
							);
						}
					} else if (property === 'tags') {
						editObj[translatedProperty] =
							calendarIO.packageTagsOutput(event[property]);
					}
					// else if (customFields && customFields[property]) {
					// 	editObj[property] = calendarIO.customFieldOutputTransform(property, changes[property], customFields, schedule, customFieldMutateOutput);
					// 	//Adjust custom field data types to strings
					// 	editObj[property] = editObj[property].toString();
					// }
					else if (customFields && customFields[property]) {
						if (
							changes[property] !== '' &&
							changes[property] !== undefined
						) {
							stuffedFields[fieldMap[property]] =
								calendarIO.customFieldOutputTransform(
									property,
									changes[property],
									customFields,
									schedule,
									customFieldMutateOutput
								);
							//Adjust custom field data types to strings
							// stuffedFields[fieldMap[property]] = stuffedFields[fieldMap[property]] === undefined ? '' : stuffedFields[fieldMap[property]].toString();
						}
					} else if (Array.isArray(event[property])) {
						editObj[translatedProperty] = changes[property].slice(); //Use slice here to clone the array rather than using a reference
					} else {
						editObj[translatedProperty] = changes[property];
					}
				}
			}

			// Join status and unscheduled edits to save as a category
			if (
				changes.hasOwnProperty('unscheduled') ||
				changes.hasOwnProperty('status')
			) {
				const categories = editObj.categories
					? editObj.categories
					: event.categories
						? event.categories.slice()
						: [];
				const unscheduledPosition = categories.indexOf(unscheduledTerm);
				const unscheduledChanged =
					changes.hasOwnProperty('unscheduled');

				const unscheduledValue = unscheduledChanged
					? changes.unscheduled
					: event.unscheduled;

				if (unscheduledValue && unscheduledPosition === -1) {
					categories.push(unscheduledTerm);
				} else if (!unscheduledValue && unscheduledPosition > -1) {
					categories.splice(unscheduledPosition, 1);
				}

				editObj.categories = categories;
			}

			//we're editing the source event (of repeating events)
			var IdToUse;
			if (sourceEventId) {
				IdToUse = sourceEventId;
			} else {
				IdToUse = event.eventID;
			}

			// editObj['attendees'] = [{
			// 	// "emailAddress": {name: "Tanner Ellen", address: "tannerellen@me.com"},
			// 	"emailAddress": {name: "Truck 1"},
			// 	// "proposedNewTime": {"@odata.type": "microsoft.graph.timeSlot"},
			// 	"status": {"response": "declined", "time": "2021-02-01T23:24:47.5232124Z"},
			// 	"type": "resource"
			//   }];

			// for (var property in stuffedFields) {
			// 	stuffedFieldOutput += '<li class="dbk-field-' + property + '">' + property + ': ' + stuffedFields[property] + '</li>';
			// }
			// stuffedFieldOutput += '<li class="dbk-field-' + 'resource' + '">' + 'resource' + ': ' + 'Tanner Ellen' + '</li>';
			// Apply field stuffing
			if (stuffedFields && Object.keys(stuffedFields).length > 0) {
				if (!editObj.hasOwnProperty(fieldMap[fieldForStuffing])) {
					editObj[fieldMap[fieldForStuffing]] =
						event[fieldForStuffing] || '<p><br></p>';
				}
				editObj[fieldMap[fieldForStuffing]] +=
					'<p class="dbk-fields" style="border: 1px dashed gray; margin: 20px auto; width: 50%; text-align: center; padding: 10px;">Additional fields available in <a href="https://app.dayback.com" target="_blank">DayBack Calendar</a> (do not modify)<span class="dbk-fields-content" style="display: block; height: 1px; overflow: hidden;">' +
					utilities.htmlEscape(JSON.stringify(stuffedFields)) +
					'</span></p>';
			}
			if (editObj.hasOwnProperty(fieldMap[fieldForStuffing])) {
				// Format description field after field stuffing
				editObj[fieldMap[fieldForStuffing]] = {
					content: editObj[fieldMap[fieldForStuffing]],
					contentType: 'html',
				};
			}
			//Add non supported fields to the description field (field stuffing)
			// if (editObj.status) {
			// 	if (editObj.status.length && editObj.status[0]) {
			// 		changes[fieldMap[fieldForUnsupported]] = changes[fieldMap[fieldForUnsupported]] +
			// 		'<br><br>' +
			// 		'<pre style="color: gray; opacity: 0.5">' +
			// 			'<strong>Do Not Modify</strong> DayBack Field Data Start -- ' +
			// 			'status!~value~!" + JSON.stringify(editObj.status) + "!~end~!' +
			// 			' -- <strong>Do Not Modify</strong> DayBack Field Data End' +
			// 		'</pre>';

			// 		editObj[fieldMap[fieldForUnsupported]] = {"content": changes[fieldForUnsupported], "contentType": "html"};

			// 	}

			// 	//Delete fields not compatible with basecamp
			// 	delete editObj.status;
			// }

			//Explicitely set timezone as it is currently a non standard field
			// editObj.timezone = schedule.timeZone;
			//Apply additional event properties necessary to properly edit the event
			additionalEditProperties.allDay = event.allDay;

			//Check if we should be changing the calendar
			if (
				event.eventID &&
				revertObject &&
				event.schedule.id !== revertObject.schedule.id
			) {
				//Change calendar
				// gBk.changeCalendar(event.id, revertObject.schedule.id, event.schedule.id, changeCalendarSuccess);
			}
			//If not just apply our event changes
			else {
				//Edit the event
				applyEditEvent();
			}

			//Callback for changing the calendar
			function changeCalendarSuccess(result) {
				if (result && result.error) {
					resultCallback(result);
				} else {
					//Edit the event
					applyEditEvent();
				}
			}

			//Apply our event result (write result data)
			function applyEditEvent() {
				//if we have an id we're updating
				if (event.eventID && !forceNewEvent) {
					if (
						(sourceEventId && !fromRepeatDeletion) ||
						fromRepeatCreation
					) {
						officeBk.updateEvent(
							schedule.id,
							IdToUse,
							editObj,
							additionalEditProperties,
							repeatingResultCallback,
							schedule.isGroup
						);
					} else {
						officeBk.updateEvent(
							schedule.id,
							IdToUse,
							editObj,
							additionalEditProperties,
							resultCallback,
							schedule.isGroup
						);
					}
				} else {
					if (editObj.recurrence) {
						officeBk.createEvent(
							schedule.id,
							editObj,
							additionalEditProperties,
							repeatingResultCallback,
							schedule.isGroup
						);
					} else {
						officeBk.createEvent(
							schedule.id,
							editObj,
							additionalEditProperties,
							resultCallback,
							schedule.isGroup
						);
					}
				}
			}

			function repeatingResultCallback(result) {
				var element = seedcodeCalendar.get('element');
				var view = seedcodeCalendar.get('view');
				var rangeStart = moment(view.start).format('YYYY-MM-DD');
				var rangeEnd = moment(view.end).format('YYYY-MM-DD');
				var eventsOutput;
				var createResult;

				if (result.type === 'seriesMaster') {
					sourceEventId = result.id;
				}

				createResult = resultCallback(result, !fromRepeatDeletion);

				if (createResult === false) {
					return;
				}

				if (!sourceEventId) {
					sourceEventId === result.id;
				}

				officeBk.getEventInstances(
					schedule.id,
					sourceEventId,
					rangeStart,
					rangeEnd,
					function (events) {
						var clientEvents = element.fullCalendar('clientEvents');
						var parentEventPosition;
						var parentEventStart;
						for (var i = 0; i < clientEvents.length; i++) {
							if (fromRepeatCreation) {
								// Find the parent event from creating the repetition. We will need to reference this later as we will get a new list of events
								// This will detect parent event from new repetition or "all future" edits
								if (
									clientEvents[i].schedule.id ===
										event.schedule.id &&
									((clientEvents[i].recurrence &&
										!clientEvents[i].recurringEventID) ||
										event.eventID ===
											clientEvents[i].eventID) &&
									clientEvents[i].start.isSame(
										event.start,
										'day'
									)
								) {
									parentEventPosition = i;
									parentEventStart =
										clientEvents[i].start.clone();
									fullCalendarBridge.removeEvents(
										element,
										clientEvents[parentEventPosition]
									);
								}
								//Update first event
							} else {
								// All repetitions need to be rerendered
								if (
									clientEvents[i].schedule.id ===
										event.schedule.id &&
									clientEvents[i].recurringEventID ===
										sourceEventId
								) {
									fullCalendarBridge.removeEvents(
										element,
										clientEvents[i]
									);
								}
							}

							// Remove current repetitions from the view as they may be different than what we want
							// if (clientEvents[i].sourceTypeID === result.sourceTypeID && !clientEvents[i].start.isBefore(changes.start, 'day') &&
							// 	((clientEvents[i].recurringEventID === sourceEventId && clientEvents[i].type !== 'seriesMaster')
							// 	// || (result.recurrence && !result.seriesMasterId)
							// 	)
							// ) {
							// 	fullCalendarBridge.removeEvents(element, clientEvents[i]);
							// }
						}

						if (events && events.value && !fromRepeatDeletion) {
							eventsOutput = mutateEvents(events.value, schedule);
							// Render repetitions back to the view
							for (var i = 0; i < eventsOutput.length; i++) {
								if (eventsOutput[i].type !== 'seriesMaster') {
									element.fullCalendar(
										'renderEvent',
										eventsOutput[i]
									);
								}
							}
						}
					}
				);
			}

			function resultCallback(result, preventRender) {
				var element = seedcodeCalendar.get('element');
				var eventResult;
				var aResult = [result];
				var message;
				var modified;

				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						resultCallback(result);
					}, 250);
					return;
				}

				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}

				//Check for errors and if we detect one revert the record
				if (result && result.error) {
					if (config.passthroughEditErrors) {
						if (callback) {
							callback(result);
						}
					} else if (config.suppressEditEventMessages) {
						dragError();
					} else if (revertFunc) {
						message =
							'There was an error saving the event and your changes will be reverted: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							null,
							null,
							'Revert Changes',
							dragError
						);
					} else if (revertObject) {
						//applyEventChanges(event, revertObject); //Would revert the event but we don't want that in this case as we don't want to lose changes
						message =
							'There was an error and your event could not be saved: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							'Return To Event',
							cancelError,
							'Revert Changes',
							confirmError
						);
					}
					return false;
				}

				//No errors found continue with updating the event with the returned data
				eventResult = mutateEvents(aResult, schedule)[0];

				eventResult.allDay = event.allDay;
				eventResult._allDay = eventResult.allDay;

				// Normalize dates
				seedcodeCalendar
					.get('element')
					.fullCalendar('buildEventDates', eventResult, eventResult);

				if (!preventRender) {
					modified = applyEventChanges(event, eventResult);
					if (modified) {
						fullCalendarBridge.update(element, event);
					}
				}
				if (callback) {
					callback(event);
				}

				function dragError() {
					if (revertFunc) {
						revertFunc(null, event, result.error);
					}
				}
				function confirmError() {
					if (event.eventID) {
						applyEventChanges(event, revertObject);
						calendarIO.assignEventColor(event);
						fullCalendarBridge.update(element, event);
						if (callback) {
							callback(false);
						}
					} else {
						//Remove event from view
						fullCalendarBridge.removeEvents(element, event);
					}
				}
				function cancelError() {
					if (!event.eventStatus) {
						event.eventStatus = {};
					}

					event.eventStatus.revertObject = revertObject;

					if (event.eventStatus.isCustomAction) {
						applyEventChanges(event, revertObject);
					} else {
						event.eventStatus.showPopover = true;
						event.eventStatus.unsavedChanges = true;
						fullCalendarBridge.update(element, event);
						//Reset event status for custom action state
						event.eventStatus.isCustomAction = false;
					}
				}
			}
		}

		//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
		function applyEventChanges(event, changes) {
			var eventDictionary = seedcodeCalendar.get('eventDictionary');
			var modified;
			//Check if we need to apply a new schedule
			if (changes.schedule && event.schedule.id !== changes.schedule.id) {
				modified = true;
				event.schedule = changes.schedule;
			}
			//Loop through all properties and set those
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd' &&
					property !== 'start' &&
					property !== 'end'
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (
							property !== 'eventID' &&
							eventDictionary[property]
						) {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						if (eventDictionary[property]) {
							modified = true;
						}
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (
					moment.isMoment(changes[property]) ||
					property === 'start' ||
					property === 'end'
				) {
					if (
						!moment(changes[property]).isSame(
							moment(event[property])
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		//Delete Event
		function deleteEvent(event, callback, schedule) {
			officeBk.deleteEvent(
				schedule.id,
				event.eventID,
				processDelete,
				schedule.isGroup
			);

			function processDelete(result) {
				var message;
				if (result) {
					message =
						'There was an error deleting the event: ' +
						result.error.message;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				calendarIO.confirmDelete(event, 0, callback);
			}
			function confirmError() {
				return;
			}
		}

		function assignColor(event) {}

		function onSignOut() {
			officeBk.clearTokenMemory();
		}

		function deleteSourceEvent(event, callback) {
			var schedule = event.schedule;
			var calendarId = schedule.id;
			officeBk.getEvent(
				calendarId,
				event.recurringEventID,
				deleteSourceEvent
			);
			function deleteSourceEvent(data) {
				officeBk.deleteEvent(
					calendarId,
					data.id,
					processDelete,
					schedule.isGroup
				);
			}
			function processDelete(data) {
				if (data) {
					message =
						'There was an error deleting the event: ' +
						data.error.message;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				callback();
			}
			function confirmError() {
				return;
			}
		}

		function deleteRepeatingInstance(event, callback) {
			deleteEvent(event, callback, event.schedule);
		}

		function updateRepeatingEventUntil(event, untilMoment, callback) {
			//get source event
			var schedule = event.schedule;
			var calendarId = schedule.id;
			var deleteRule = false;

			officeBk.getEvent(calendarId, event.recurringEventID, updateEvent);
			//create request with updated rule
			function updateEvent(data) {
				var sourceEvent = data;
				var firstInstance = false;
				var sourceStart;

				sourceStart = $.fullCalendar.createTimezoneTime(
					moment.utc(sourceEvent.start.dateTime),
					false,
					true
				);

				if (
					sourceStart.format('YYYYMMDD') ===
						event.start.format('YYYYMMDD') ||
					!sourceEvent.recurrence
				) {
					//if this is the first instance, then we want to handle differently
					callback({
						firstInstance: true,
						recurringEventID: event.recurringEventID,
					});
					return;
				}

				var recurrence = sourceEvent.recurrence;
				var originalRangeEnd =
					recurrence.range.type === 'noEnd'
						? ''
						: recurrence.range.endDate;

				// Update recurrence object
				recurrence.range.endDate = untilMoment.format('YYYY-MM-DD');
				recurrence.range.type = 'endDate';

				var request = firstInstance
					? {recurrence: null}
					: {recurrence: recurrence};

				officeBk.updateEvent(
					calendarId,
					sourceEvent.id,
					request,
					null,
					runCallback,
					schedule.isGroup
				);

				function runCallback(data) {
					var recurrenceOutput = JSON.parse(
						JSON.stringify(sourceEvent.recurrence)
					);

					// We need to check if the new start date matches any of the recurrence days and if not we need to change the recurrence day to the new start date day

					// New event recurrence
					recurrenceOutput.range.startDate = untilMoment
						.clone()
						.add(1, 'day')
						.format('YYYY-MM-DD');
					if (originalRangeEnd) {
						// Update recurrence object
						recurrenceOutput.range.endDate = originalRangeEnd;
						recurrenceOutput.range.type = 'endDate';
					} else {
						delete recurrenceOutput.range.endDate;
						recurrenceOutput.range.type = 'noEnd';
					}

					sourceEvent.recurrence = recurrenceOutput;
					callback(sourceEvent);
				}
			}
		}

		function updateSourceEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			callback
		) {
			editEvent(
				event,
				revertObject,
				revertFunc,
				changes,
				null,
				processResult,
				event.schedule,
				event.recurringEventID
			);
			function processResult(data) {
				callback(event);
			}
		}
	}
})();
